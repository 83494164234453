<template>
	<tr v-if="response"
		@click="() => {if( !disabled ) { $emit('toggle') }}"
	>
		<td>
			{{ section.name }}
			<span class="font-weight-bold grey--text" v-if="response.multiple">
				<br/>{{ category.name }}
			</span>
		</td>
		<td>
			<theme-chip :id="response.section"></theme-chip>
		</td>
		<td>
			<status-chip :id="id"></status-chip>
		</td>
		<td>

			<v-checkbox
				:disabled="disabled"
				:value="active"
				@input="$emit('toggle')"
				color="primary"
			></v-checkbox>
		</td>
	</tr>
</template>
<script>
import ThemeChip from "@c/navigation/assessment/sections/ThemeChip";
import StatusChip from "@c/navigation/assessment/sections/StatusChip";
export default {
	name: "CollaborateRow",
	props: {
		id: { type: String }, // the id of the response
		active: { type: Boolean },
	},
	computed: {
		section() {
			return this.$store.state.sections.data[this.response.section];
		},
		response() {
			return this.$store.state.sectionResponses.data[this.id];
		},
		rule() {
			return this.$store.getters.getRuleForResponse(this.id);
		},
		category() {
			if (this.rule) {
				return this.$store.state.categoryOptions.data[this.rule.value];
			}
			return null;
		},
		disabled() {
			return !this.$store.getters["sectionResponses/canDelegate"](this.id);
		},
		users() {
			return this.$store.state.users.data;
		},
		delegates() {
			const self = this;
			if (self.response.status == "delegated") {
				return Object.keys(self.response.users).filter(
					(u) => self.response.users[u] == "delegate"
				);
			}
			return [];
		},
	},
	data() {
		return {};
	},
	methods: {},
	components: {
		StatusChip,
		ThemeChip,
	},
};
</script>
