<template>
	<v-item-group :value="value" @change="change" multiple>
		<v-simple-table width="100%" dense class="pl-0 pr-0">
			<v-item
				v-slot="{active, toggle}"
				v-for="section in order"
				:key="section.response"
				:value="section.response"
			>
				<collaborate-row
					:id="section.response"
					@toggle="(v) => toggle(v)"
					:active="active"
				></collaborate-row>
			</v-item>
		</v-simple-table>
	</v-item-group>
</template>

<script>
	import CollaborateRow from "@c/enhanced-delegation/CollaborateRow";
	export default {
		name: "Collaborate",
		props: {
			value: {type: Array, default: () => ([])},
			initialValue: {type: String}
		},
		computed: {
			order() {
				return this.$store.getters.order;
			}
		},
		data: () => {
			return {
				list: null,
				delegate: null,
			};
		},
		components: {
			CollaborateRow
		}, 
		methods: {
			change(v){
				this.$emit('input', v )
			}
		}, 
		created(){
			if( this.initialValue ){
				this.$emit('input', [this.initialValue])
			}
		}	
	};
</script>
