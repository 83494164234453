<template>
	<mw-dialog v-model="dialog" large title="Create Delegation">
		<template v-slot:button>
			<v-tooltip v-if="icon" left>
				<template v-slot:activator="{on}">
					<v-btn v-on="on" icon @click="dialog = true">
						<v-icon v-if="icon">mdi-account-plus</v-icon>
					</v-btn>
				</template>
				Delegate section to colleague
			</v-tooltip>
			<v-list-item v-else @click="dialog = true">
				<v-list-item-title>
					{{ $t("buttons.delegate") }}
				</v-list-item-title>
			</v-list-item>
		</template>
		<template v-slot:dialog-body>
			<v-sheet flat>
				<div v-html="$t('collaboration.delegation.before_delegate')"></div>
				<v-stepper elevation="0" v-model="step">
					<collaboration-wizard-header
						text="Sections"
						:value="step"
					></collaboration-wizard-header>
					<v-stepper-content step="1">
						<v-card elevation="0">
							<collaborate-list
								v-model="selected"
								:initial-value="id"
							></collaborate-list>
							<v-card-actions class="mt-8 pb-0">
								<v-btn text @click="cancel">Cancel</v-btn>
								<v-spacer></v-spacer>
								<v-btn
									color="primary"
									@click="step = 2"
									:disabled="selected.length == 0"
								>
									Continue
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>

					<v-stepper-content step="2">
						<v-card elevation="0">
							<p>
								To create your delegation, please either select a user below or
								create a new user by typing their name.
							</p>
							<add-colleague v-model="colleague"></add-colleague>
							<v-card-actions class="mt-8 mb-0">
								<v-btn text @click="cancel">Cancel</v-btn>
								<v-spacer></v-spacer>
								<v-btn color="primary" @click="step = 3" :disabled="!colleague">
									Continue
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>
					<v-stepper-content step="3">
						<v-card elevation="0">
							<p>Please confirm by clicking on 'complete' to create your delegation.</p>
							<v-checkbox
								v-model="notify"
								label="Notify colleague?"
							></v-checkbox>
							<v-card-actions class="mt-8 mb-0">
								<v-btn text @click="cancel">Cancel</v-btn>
								<v-spacer></v-spacer>
								<v-btn color="primary" @click="complete">
									Complete
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>
				</v-stepper>
			</v-sheet>
		</template>
	</mw-dialog>
</template>

<style lang="less">
	.button {
		cursor: pointer;
	}
</style>
<script>
	import MwDialog from "@c/ui/MwDialog";
	import AddColleague from "@c/collaboration/AddColleague";
	import CollaborationWizardHeader from "@c/collaboration/CollaborationWizardHeader.vue";
	import CollaborateList from "@c/enhanced-delegation/CollaborateList.vue";

	export default {
		name: "Delegate",
		props: {
			id: {type: String}, // the ID of the response,
			icon: {type: Boolean, default: false}
		},
		data: () => {
			return {
				step: 1,
				selected: [],
				colleague: null,
				dialog: false,
				notify: true
			};
		},
		computed: {
			response() {
				return this.$store.state.sectionResponses.data[this.id];
			}
		},
		components: {
			CollaborationWizardHeader,
			MwDialog,
			AddColleague,
			CollaborateList
		},
		methods: {
			clear() {
				this.selected = [];
				this.colleague = null;
				this.step = 1;
			},
			cancel() {
				this.dialog = false;
				this.clear();
			},
			complete() {
				const self = this;
				const user = self.colleague;
				const notify = self.notify;
				self.selected.forEach((response) => {
					self.$store
						.dispatch("delegateTo", {response, user, notify})
						.then(() => {
							self.dialog = false;
						});
				});
				self.dialog = false;
			}
		}
	};
</script>
